import React from 'react'
import axios from 'axios'
//import moment from 'moment';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Form from '../Form/index'
import style from '../admin.module.scss'
import {
	//Link as ScrollLink,
	animateScroll as scroll
} from 'react-scroll'
//import BlogCard from './../../BlogCard'

class Home extends React.Component {
	constructor(props) {
		super(props)

		this.handleDelete = this.handleDelete.bind(this)
		this.handleEdit = this.handleEdit.bind(this)

		//this.state = {}

	}

	componentDidMount() {

		// console.log(moment(new Date()).fromNow())
		const { onLoad } = this.props
		axios('https://www.naturalstatepsychotherapy.com:3002/').then((res) => onLoad(res.data))
		//axios('http://localhost:8000/api/articles').then((res) => this.setState('asdf', res.data)).then(console.log(this.state))
	}

	handleDelete(id) {
		const { onDelete } = this.props
		axios.delete('https://www.naturalstatepsychotherapy.com:3002/', { params: { id: id } }).then(function (response) {
			onDelete(response.data)
			console.log(response.data)
		})
	}
	scrollToTop = () => {
		scroll.scrollToTop()
	}
	handleEdit(article) {
		const { setEdit, forEdit } = this.props
		setEdit(article)
		forEdit(true)
		this.scrollToTop()
	}

	_logout = withRouter(({ history }) => (
		<button
			className={style.button}
			onClick={() => {
				localStorage.clear()
				history.push('/blog')
			}}
		>
			{' '}
			Sign out
		</button>
	))
	render() {
		const { articles } = this.props

		return (
			<div className={style.container}>
				<div>
					<div className={style.button}>
						<this._logout />
					</div>
					<Form className={style.form} />
				</div>

				{/* <div>
					<div className={style.containeru}>
						{articles.map((article) => <BlogCard key={this._id} article={article} />)}
					</div>
				</div> */}
				<div>
					{articles.map((article) => {
						return (
							<div className={style.post} key={article._id}>
								<div className={style.postCard}>
									<img className={style.postImage} src={article.image} alt="blog post" />

									<div className={style.postWrapper}>
										<div className={style.postBody}>
											<div className={style.postTitle}>
												{article.title}
											</div>
											{article.body}

											<div className={style.postAuthor}>
												{article.author}
											</div>
										</div>
										<div className={style.postButtons}>
											<button onClick={() => this.handleDelete(article._id)} className={style.deleteButton}>
												Delete
                                         </button>
											<button onClick={() => this.handleEdit(article)} className={style.editButton}>
												Edit
                                        </button>
										</div>
									</div>
								</div>
							</div>
						)
					}
					)}
				</div >
			</div>
		)
	}
}
const mapStateToProps = (state) => ({
	articles: state.home.articles,
	forEdit: state.home.forEdit
})

const mapDispatchToProps = (dispatch) => ({
	onLoad: (data) => dispatch({ type: 'HOME_PAGE_LOADED', data }),
	onDelete: (data) => dispatch({ type: 'DELETE_ARTICLE', data }),
	setEdit: (article) => dispatch({ type: 'SET_EDIT', article }),
	forEdit: (forEdit) => dispatch({ type: 'FOR_EDIT', forEdit })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
