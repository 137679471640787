import React from 'react'
import style from './questions.module.scss'
import Footer from '../Footer'
//https://www.npmjs.com/package/react-collapsible-component
import {
    CollapsibleComponent,
    CollapsibleHead,
    CollapsibleContent
} from "react-collapsible-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { animateScroll as scroll } from 'react-scroll'
import { Link } from 'react-router-dom'

class Questions extends React.Component {
    scrollToTop = () => {
        scroll.scrollToTop()
    }
    componentDidMount() {
        this.scrollToTop();
    }
    render() {
        return (
            <div>
                <div className={style.aboutcontainer}>
                    {/* <div className={style.text}>
                        Click to find answers to common questions
				</div> */}
                    {/* <div style={{ paddingTop: '10px' }} /> */}
                </div>
                <div className={style.CollapsibleComponent}>

                    <div style={{ paddingTop: '25px' }} />
                    <CollapsibleHead className={style.head}>
                        <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} />What methods of payment do you accept?</div>
                    </CollapsibleHead>
                    <CollapsibleContent className={style.CollapsibleComponentText} >
                        <div className={style.answer}>
                            I accept cash, all major credit cards, as well as Health Savings Account (HSA) Cards. I am in network with Blue Cross Blue Shield, United Healthcare, and Ambetter.
                        </div>
                    </CollapsibleContent>




                    <div style={{ paddingTop: '25px' }} />

                    <CollapsibleComponent>

                        {/* <CollapsibleHead className={style.head} isExpanded={true}>
                            Common Questions
                     </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} isExpanded={true}>
                            <div>
                                Click to find the answers
                            </div>
                        </CollapsibleContent> */}
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} />Do you offer teleheath appointments?</div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                Yes. I offer secure video appointments.
                            </div>
                        </CollapsibleContent>

                        {/* <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} />Do you take insurance?</div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                I do not accept insurance. I do accept cash, all major credit cards, as well as Health Savings Account (HSA) Cards.
                            </div>
                        </CollapsibleContent> */}


                        <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} /> How do I schedule an appointment?</div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                Send me an email at <a className={style.href} href="mailto:meredith@naturalstatepsychotherapy.com" target="_blank" rel="noopener noreferrer">meredith@naturalstatepsychotherapy.com</a> or give me a call at (513) 655-4459. If I dont answer leave a message and I will contact you as soon as I can. If it is an emergency, dial 911.
                            </div>
                        </CollapsibleContent>

                        {/* <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} />How much can I expect to pay for therapy?</div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                My standard fee is $125 per 50-60 min session. I offer a sliding scale for a select number of clients who are unable to afford my full fee. Please reach out to me and we can discuss it.
                            </div>
                        </CollapsibleContent> */}

                        {/* 
                        <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} /> Do I really need therapy? It's probably nothing.</div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                Because you have ended up here, I’m going to guess that you are seeking something more. Even if you are able to tick all the boxes of “normal” functioning (I go to work, I have relationships, I’m keeping a plant alive, etc.) this process can still be enormously beneficial for you. I would love to hear more about your situation and discuss how therapy could be helpful for your unique concerns.
                            </div>
                        </CollapsibleContent>

                        <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} />Am I too messed up? Can anything really change for me? Can you fix me? </div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                No, you are not too messed up. You are not doomed to an unsatisfying life. You just need some help to find your natural ability to heal and grow. I know it sounds cheesy, but you’ve already taken the first step towards making a change by even asking the question. I can’t fix you because you are not broken. If you need more convincing of that fact, give me a call.
                            </div>
                        </CollapsibleContent> */}

                        <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} />How long can I expect to be in therapy? </div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                The length of treatment is dependent on what you’ve got going on, what your goals are, and other factors unique to you. I believe that a longer term relationship offers greater opportunities for lasting change, but you are in charge of determining when your personal goals for therapy have been met. Many people engage with therapy in cycles and may return to the work at various different life stages.
                            </div>
                        </CollapsibleContent>

                        <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} />Do you prescribe medication?</div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                I do not prescribe medication. If you are interested in medication for mental health issues and want to talk through your feelings and concerns surrounding the topic, I would be happy to assist you in that exploration. Otherwise, I can refer you to other professionals in the field who may be able to help you.
                            </div>
                        </CollapsibleContent>

                        <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} />Are you the right therapist for me?</div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                Great question. So much of success in therapy is dependent on the relationship you are able to create with your therapist. I encourage you to speak with several different therapists and make a thoughtful decision based on what feels right for you. I offer a free phone consultation if you would like to chat.
                            </div>
                        </CollapsibleContent>

                        {/* <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} /> Is there a long wait to schedule an appointment with you?</div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                I am typically able to get you scheduled within 5 days of our initial consultation.

                            </div>
                        </CollapsibleContent> */}

                        {/* <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} />Do you offer phone or video appointments? </div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                I do not offer phone or video appointments. I believe that I can be most effective for you during face to face meetings. If you are having concerns about making it to my office, please reach out and let’s see if there might be another option that would work for you.
                            </div>
                        </CollapsibleContent> */}

                        {/* <div style={{ paddingTop: '25px' }} />
                        <CollapsibleHead className={style.head}>
                            <div className={style.questions}> <FontAwesomeIcon style={{ fontSize: '25px', paddingRight: '15px', paddingTop: '5px' }} icon={faPlus} />Do you take insurance?</div>
                        </CollapsibleHead>
                        <CollapsibleContent className={style.CollapsibleComponentText} >
                            <div className={style.answer}>
                                We do not accept insurance.
                            </div>
                        </CollapsibleContent> */}

                        <div className={style.tag}> If you have any more questions
                            <br />
                            <Link style={{ paddingLeft: '8px', textDecoration: 'none' }} to="contact"><div className={style.button}>GET IN TOUCH</div></Link>

                        </div>
                    </CollapsibleComponent>

                </div>
                <div style={{ paddingTop: '80px', backgroundColor: '#fafafa' }} />
                <Footer />
            </div>
        )
    }
}

export default Questions
