import React from 'react'
import style from './resources.module.scss'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faQuestion, faAllergies, faAnchor, faBookDead, faMedal, faAirFreshener, faListUl } from '@fortawesome/free-solid-svg-icons'
import Footer from '../Footer'
import { animateScroll as scroll } from 'react-scroll'
import logo from './../../assets/NSPL_Logo.jpg'
import logospanish from './../../assets/NSPC_Logo_Spanish.jpg'

class Resources extends React.Component {
	scrollToTop = () => {
		scroll.scrollToTop()
	}
	componentDidMount() {
		this.scrollToTop();
	}
	render() {
		return (
			<div className={style.container}>
				{/* <a style={{ textDecoration: 'none' }} href="https://support.simplepractice.com/hc/article_attachments/360034850272/ClientPortal-Telehealth-04.pdf"><div className={style.button}>DOWNLOAD TELEHEALTH <br /> PDF WALKTHROUGH</div></a> */}


				<div className={style.text}>
					<a className={style.href} href='https://www.mentalhealthnetwork.net' target="_blank" rel="noopener noreferrer">Mental Health Network of NW Arkansas</a>
				</div>

				<div className={style.text}>
					<a className={style.href} href='https://nwasexualassault.org/' target="_blank" rel="noopener noreferrer">NWA Center for Sexual Assult</a>
					<br /> 24-hr Crisis Help Line <br />
					800-794-4175
				</div>

				<div className={style.text}>
					<a className={style.href} href='https://health.uark.edu/mental-health/suicide-prevention.php' target="_blank" rel="noopener noreferrer">Pat Walker Mental Health Crisis Line at the University of Arkansas</a>
					<br />479-575-5276
					<br />
				</div>
				<div className={style.text}>
					<a className={style.href} href='https://www.trevorproject.org' target="_blank" rel="noopener noreferrer">The Trevor Project - LGBTQ+ Crisis Hotline</a>
					<br />1-866-488-7386 <br />

				</div>
				<div className={style.text}>
					<a className={style.href} href='https://www.crisistextline.org' target="_blank" rel="noopener noreferrer">Crisis Text Line</a>
					<br />Text HOME to 741741 <br />

				</div>
				<div className={style.text}>
					<a className={style.href} href='https://www.childhelp.org' target="_blank" rel="noopener noreferrer">Child Abuse Hotline</a>
					<br />1-800-422-4453 <br />

				</div>
				<div className={style.text}>
					<a className={style.href} href='https://www.thehotline.org' target="_blank" rel="noopener noreferrer">Domestic Violence Hotline</a>
					<br />1-800-799-7233 <br />

				</div>
				<div className={style.text}>
					<a className={style.href} href='https://www.samhsa.gov/find-help/disaster-distress-helpline' target="_blank" rel="noopener noreferrer">Substance Abuse and Mental Health Services Administration’s (SAMHSA’s) Disaster Distress Helpline</a>
					<br />1-800-985-5990 or text TalkWithUs to 66746<br />
					(TTY 1-800-846-8517)
				</div>
				<br />

				<div className={style.hotlinecontainer}>
					<a href={'https://suicidepreventionlifeline.org/talk-to-someone-now/'} target="_blank" rel="noopener noreferrer">
						<img className={style.hotline} alt='suicide prevention hotline' src={logo}></img>
					</a>
					<a href={'https://suicidepreventionlifeline.org/help-yourself/en-espanol/'} target="_blank" rel="noopener noreferrer">
						<img className={style.hotline} alt='suicide prevention hotline' src={logospanish}></img>
					</a>
				</div>

				<br />
				<br />
				<br />
				{/* <img src={'https://images.pexels.com/photos/461049/pexels-photo-461049.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'} alt='holding hands' /> */}
				{/* <div className={style.listContainer}>
					<div className={style.center}>
						<ul>
							<li><div className={style.list}><FontAwesomeIcon icon={faQuestion} /><br /> dolore magna aliqua. Ut enim ad minim , quis nostrud exercitation usdf</div></li>
							<li><div className={style.list}><FontAwesomeIcon icon={faListUl} /><br />aet  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation usdf</div></li>
							<li><div className={style.list}><FontAwesomeIcon icon={faMedal} /><br />asdfet dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation u</div></li>
							<li><div className={style.list}><FontAwesomeIcon icon={faAirFreshener} /><br />et dolore  aliqua. Ut enim ad minim veniam, quis nostrud exercitation uasdf</div></li>
							<li><div className={style.list}><FontAwesomeIcon icon={faAllergies} /><br /> dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation usdf</div></li>
							<li><div className={style.list}><FontAwesomeIcon icon={faAnchor} /><br />aet dolore aliqua. Ut enim ad minim veniam, quis nostrud exercitation usdf</div></li>
							<li><div className={style.list}><FontAwesomeIcon icon={faBookDead} /><br />asdet dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation uf</div></li>
						</ul>
					</div>
				</div>
				<div style={{ paddingTop: '100px', backgroundColor: '#fafafa' }} /> */}
				<Footer />
			</div>
		)
	}
}

export default Resources
