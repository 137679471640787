import React from 'react'
import style from './expect.module.scss'
import Footer from '../Footer'
import { animateScroll as scroll } from 'react-scroll'
import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
//require('./../../assets/magnolia.jpg')

//https://mapstyle.withgoogle.com
class Expect extends React.Component {
	scrollToTop = () => {
		scroll.scrollToTop()
	}
	componentDidMount() {
		this.scrollToTop();
	}
	render() {
		return (
			<div className={style.aboutcontainer}>
				<div className={style.header}>Welcome to Natural State Psychotherapy</div>

				<div className={style.split}>

					<div className={style.info}>
						When you are ready to make an appointment, reach out to me by phone or e-mail. We will have a brief consultation about what you are currently experiencing and how I may be able to help. If it is determined that you need something different than what I am able to offer you, I will give you referrals to other competent providers in the area. Otherwise, I will schedule an appointment for you.
						<br />
						<br />
						Before your first appointment, I will email you a link to a client portal where you will find intake documents to complete. The information in these documents will allow me to find the best way to serve you. During our first session we will discuss your treatment goals and focus on getting to know each other.
						<Link style={{ textDecoration: 'none' }} to="contact"><div className={style.button}>READY TO BOOK AN APPOINTMENT?</div></Link>

					</div>


					<div style={{ paddingTop: '30px' }} />
					<div className={style.slidercontainer}>
						<Carousel
							//fade={true}
							indicators={false}
							controls={false}
						>
							<Carousel.Item>
								<img
									className={style.slider}
									src={require('./../../assets/therapy_room_2023.jpg')}
									alt="Office"
								/>
								<Carousel.Caption>
									{/* <h3>Entrance</h3> */}
									{/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
								</Carousel.Caption>
							</Carousel.Item>


						</Carousel>
					</div>
				</div>
				<div style={{ paddingTop: '100px' }} />
				<Footer />
			</div>
		)
	}
}

export default Expect
