import React, { Component } from 'react'
import style from './footer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from '@fortawesome/free-solid-svg-icons'

export default class Footer extends Component {
    render() {
        return (
            <div className={style.container}>
                <div className={style.stripe}>

                </div>

                <div className={style.name}>

                    {/* <div className={style.flexitcontainer}>
                        <div className={style.flexit}>NATURAL STATE PSYCHOTHERAPY.</div>
                        <div className={style.flexitcontainer}><div className={style.flexit}> 31 EAST CENTER STREET.</div>
                            <div className={style.flexit}> FAYETTEVILLE. AR. 72701</div>
                        </div> */}
                    <div className={style.flexitcontainer}>
                        <div className={style.flexit}>NATURAL STATE PSYCHOTHERAPY</div>
                        <div className={style.flexit}> 31 EAST CENTER STREET. FAYETTEVILLE. AR. 72701</div>
                        <div className={style.flexit}> </div>

                    </div>
                    <a className={style.href} href="mailto:meredith@naturalstatepsychotherapy.com" target="_blank" rel="noopener noreferrer">MEREDITH@NATURALSTATEPSYCHOTHERAPY.COM</a>
                    <div>

                        <span>    <div>(513) 655-4459</div>      </span>
                        <span><FontAwesomeIcon style={{ fontSize: '12' }} icon={faCopyright} /> Meredith Kimbrough, 2023. All Rights Reserved.</span> <br /> <br />
                    </div>

                </div>



            </div >

        )
    }
}