import React from 'react';
import {
    withRouter,
    Switch,
    Route,
} from 'react-router-dom';
import { Home, Navbar1 } from '../../components';
// import { Login } from '../../components';
// import { Admin } from '../../components';
// import { Blog } from '../../components';
import { About } from '../../components';
import { Expect } from '../../components';
import { Resources } from '../../components';
import { Contact } from '../../components';
import { Services } from '../../components';
import { Questions } from '../../components';
// import { TOKEN } from '../creds'
import style from "./app.scss";

// const PrivateRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={(props) => (
//         localStorage.getItem('token') === TOKEN
//             ? <Component {...props} />
//             : <Redirect to={{
//                 pathname: '/login',
//                 state: { from: props.location }
//             }} />
//     )} />
// )

const App = (props) => {
    return (
        <div className={style.asdf} style={{ backgroundColor: '#fafafa' }}>
            <Navbar1 />
            <Switch>
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/covid-19" component={Covid19} /> */}
                <Route exact path="/home" component={Home} />
                {/* <Route exact path="/login" component={Login} /> */}
                <Route exact path="/about" component={About} />
                <Route exact path="/expect" component={Expect} />
                <Route exact path="/questions" component={Questions} />
                <Route exact path="/resources" component={Resources} />
                {/* <Route exact path="/blog" component={Blog} /> */}
                <Route exact path="/services" component={Services} />
                <Route exact path="/contact" component={Contact} />
                {/* <PrivateRoute exact path="/admin" component={Admin} /> */}
            </Switch>
        </div>
    )
}

export default withRouter(App);