import React from 'react'
import style from './about.module.scss'
import Footer from '../Footer'
import portrait from './../../assets/Meredith_2023_Headshot.jpg'
import { Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

class About extends React.Component {
	scrollToTop = () => {
		scroll.scrollToTop()
	}
	componentDidMount() {
		this.scrollToTop();
	}
	render() {
		return (
			<div className={style.pagecontainer}>



				<div className={style.aboutcontainer}>

					<div className={style.text}>

						<div className={style.missiontext}>Empowering <br /> Individuals and Couples<br />  to Find a New Way Forward.</div>

						<div className={style.line} />

						If you feel like something isn’t right and you are considering therapy - welcome! The toughest part is realizing that something really can change, and that you might need a little help to get where you want to be. The truth is, we all need a little help to get where we want to be. <br /><br />
						Here’s what I’m offering: <br />
						<div className={style.italics}>
							A relationship that does not require you to be less than what you are, a space that is safe and accepting of every part of you (yes, even <i>that</i> part), time to ask the big questions that will lead you back to yourself, and new strategies that can carry you forward into a more energetic and fulfilling life.
						</div>
						<br />
						If you’re ready to get started, <br />
						<Link style={{ textDecoration: 'none' }} to="contact"><div className={style.button}>GIVE ME A CALL</div></Link>
						<div style={{ marginTop: '20px' }} />
						I look forward to getting to know you.
					</div>


					<div className={style.containerR}>
						<img src={portrait} className={style.portrait} alt='portrait'></img>
						<div className={style.text1}>
							{/* I received a BA in Psychology from Texas State University and an MS in Counseling from the University of Arkansas. I am licensed by the <a target="_blank" rel="noopener noreferrer" className={style.href} href={'http://search.statesolutions.us/?A=ABEC&AID=RS'}>Arkansas Board of Examiners in Counseling and Marriage and Family Counseling</a>, and I am a member of the <a target="_blank" rel="noopener noreferrer" className={style.href} href={'https://www.counseling.org/'}>American Counseling Association</a>.
							<br /><br />
							In my spare time I like to play music, hang out with my cats and my partner, and read interesting stuff about humans. */}
							I earned my Bachelors in Psychology from Texas State University and my Masters in Counseling from the University of Arkansas. I am licensed by the <a target="_blank" rel="noopener noreferrer" className={style.href} href={'http://search.statesolutions.us/?A=ABEC&AID=RS'}>Arkansas Board of Examiners in Counseling and Marriage and Family Counseling</a>. I approach each client with openness, acceptance, empathy, curiosity, and love. My therapeutic style is relational and attachment-based, which means the primary tool in our work together will be a reliable and safe relationship between the two of us where you can begin to share your story and have it received with compassion.
							<br /><br />
							I believe that a lot of suffering stems from the ways in which we deny ourselves the ability to be who we truly are. Many of us have had to hide away parts of ourselves in an attempt to stay emotionally or physically safe in childhood, and the consequences are suffering and symptoms. In our work together, we will spend time getting to know you. We will explore the barriers to your full expression and we will confront them together so that you can move forward with energy, confidence, and strength.
							<br /><br />
						</div>
						{/* You can also check out my profile on <a target="_blank" rel="noopener noreferrer" className={style.href} href={'https://www.psychologytoday.com/us/therapists/meredith-kimbrough-fayetteville-ar/733444?preview=1&return_to=https%3A%2F%2Fmember.psychologytoday.com%2Fus%2Fprofile%2Fshare&_ga=2.171011104.1829810015.1583073964-1269469459.1581516262'} >Psychology Today</a>. */}

					</div>



				</div>


				<Footer />
			</div >
		)
	}
}

export default About
