import React from 'react'
import axios from 'axios'
import style from './contact.module.scss'
import SimpleMap from '../Map'
import Footer from '../Footer'
import { animateScroll as scroll } from 'react-scroll'
import Loader from 'react-loader-spinner'

class Contact extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			name: '',
			email: '',
			message: '',
			submitPause: false
		}
	}
	scrollToTop = () => {
		scroll.scrollToTop()
	}
	componentDidMount() {
		this.scrollToTop();
	}
	handleSubmit(e) {

		e.preventDefault()
		this.setState({ submitPause: true })
		axios({
			method: 'POST',
			url: 'https://www.naturalstatepsychotherapy.com:3002/email',
			data: this.state
		})
			.then((response) => {
				console.log(response.data.status)
				if (response.data.status === 'success') {
					alert('Message Sent.')
					this.resetForm()
					this.setState({ submitPause: false })
				} else if (response.data.status === 'fail') {
					this.setState({ submitPause: false })
					alert('Message failed to send. Please try again shortly.')

				}
			})
			.catch((error) => {
				if (error) {
					this.setState({ submitPause: false })
					alert('The contact form is not responding, please send an email to meredith@naturalstatepsychotherapy.com from your personal account, or give us a call at (513) 655-4459.')
				}
			})
	}

	resetForm() {
		this.setState({ name: '', email: '', message: '' })
	}

	render() {
		// return (
		// 	<div>
		// 		<div className={style.text}>Contact: Meredith Kimbrough <br /> Email: meredith@naturalstatepsychotherapy.com</div>
		// 	</div>
		// )
		return (
			<div className={style.background}>
				<div className={style.container}>

					<div className={style.info}>

						{/* <span style={{ fontSize: '25px' }}>CONTACT:</span> <br /> <br /> */}

						<br />
						Meredith Kimbrough MS, LPC<br />
						31 E Center Street<br />
						Fayetteville, AR 72701<br />
						<br />
						Located above A Taste of Thai and Petra Cafe.
						<br /><br />
						(513) 655-4459 <br /><br />
						I am often not immediately available by phone, <br />you may get a quicker response by emailing me at:  <br /><br />
						<a style={{ textDecoration: 'none' }} className={style.href} href="mailto:meredith@naturalstatepsychotherapy.com" target="_blank" rel="noopener noreferrer">meredith@naturalstatepsychotherapy.com</a>
						<br />
						<br />
					</div>

					<div className={style.contactFormLoader}>
						<div>
							{this.state.submitPause && (
								<Loader
									className={style.contactLoading}
									type="Circles"
									color="grey"
									height={200}
									width={200}
									timeout={10000}
								/>)}
						</div>
						<form className={style.contactForm} method="POST" onSubmit={this.handleSubmit.bind(this)}>
							<div>
								<input
									type="text"
									value={this.state.name}
									onChange={this.onNameChange.bind(this)}
									name="name"
									placeholder="NAME"
									className={style.contactFormInput}
									required
								/>
							</div>
							<div>
								<input
									type="email"
									value={this.state.email}
									onChange={this.onEmailChange.bind(this)}
									name="email"
									placeholder="EMAIL"
									className={style.contactFormInput}
									required
								/>
							</div>
							<div>
								<textarea
									name="message"
									value={this.state.message}
									onChange={this.onMessageChange.bind(this)}
									className={style.contactFormInput}
									placeholder="MESSAGE"
									required
								/>
							</div>
							<div>
								<button className={style.contactFormButton} type="submit">
									Send
								</button>
							</div>
						</form>
					</div>

					<div className={style.map}>
						<SimpleMap />
						{/* <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3225.2719019561678!2d-94.16060924886371!3d36.062471580009436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c96ed6488281bd%3A0xcaefa1377d9c2876!2s31%20E%20Center%20St%2C%20Fayetteville%2C%20AR%2072701!5e0!3m2!1sen!2sus!4v1581967444072!5m2!1sen!2sus" width="500" height="400" frameborder="0" style={{ border: 0, paddingTop: 20 }} allowfullscreen="" /> */}
					</div>
					<div style={{ paddingTop: '50px' }} />
				</div >
				<Footer />
			</div>
		)
	}

	onNameChange(event) {
		this.setState({ name: event.target.value })
	}

	onEmailChange(event) {
		this.setState({ email: event.target.value })
	}

	onMessageChange(event) {
		this.setState({ message: event.target.value })
	}
}
export default Contact
