import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import style from './../admin.module.scss'

class Form extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			title: '',
			body: '',
			author: '',
			image: '',
			forEdit: false
		}

		this.handleChangeField = this.handleChangeField.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	componentDidMount() { }

	componentWillReceiveProps(nextProps) {
		if (nextProps.articleToEdit) {
			this.setState({
				title: nextProps.articleToEdit.title,
				body: nextProps.articleToEdit.body,
				author: nextProps.articleToEdit.author,
				image: nextProps.articleToEdit.image,
				forEdit: nextProps.forEdit
			})
		}
	}

	async handleSubmit() {
		const { onSubmit, articleToEdit, onEdit } = this.props
		const { title, body, author, image, forEdit } = this.state

		if (forEdit === false) {
			axios
				.post('https://www.naturalstatepsychotherapy.com:3002/', {
					title,
					body,
					author,
					image
				})
				// .then(axios('http://localhost:8000/api/articles')
				.then((res) => onSubmit(res.data))
			// .then(function (response) {
			// 	onSubmit(response.data)
			// 	//wtf?
			// 	onSubmit(response.data)
			// })

			return this.resetForm();
		}
		if (forEdit === true) {
			axios
				.patch('https://www.naturalstatepsychotherapy.com:3002/', {
					id: articleToEdit._id,
					title,
					body,
					author,
					image
				})
				.then((res) => onEdit(res.data))

			return this.resetForm();
		} else alert('Please try again.')
	}

	handleChangeField(key, event) {
		this.setState({
			[key]: event.target.value
		})
	}

	resetForm = () => {
		this.setState({ title: '', body: '', author: '', image: '', forEdit: false })
	}

	render() {
		const { articleToEdit } = this.props
		const { title, body, author, image, forEdit } = this.state
		return (
			<div style={{ width: '100%' }}>
				<div className={style.formContainer}>
					<input
						onChange={(ev) => this.handleChangeField('title', ev)}
						value={title}
						className={style.formInput}
						placeholder="Article Title"
					/>
					<textarea
						onChange={(ev) => this.handleChangeField('body', ev)}
						className={style.formInput}
						placeholder="Article Body"
						value={body}
					/>
					<input
						onChange={(ev) => this.handleChangeField('author', ev)}
						value={author}
						className={style.formInput}
						placeholder="Article Date"
					/>
					<input
						onChange={(ev) => this.handleChangeField('image', ev)}
						value={image}
						className={style.formInput}
						placeholder="Article Image Url"
					/>
					<div className={style.buttons}>
						<button onClick={this.handleSubmit} className={style.formButton}>
							{articleToEdit && forEdit ? 'Update' : 'Submit'}
						</button>
						<button onClick={this.resetForm} className={style.formButton}>
							Reset
					</button>
					</div>
				</div>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	onSubmit: (data) => dispatch({ type: 'SUBMIT_ARTICLE', data }),
	onEdit: (data) => dispatch({ type: 'EDIT_ARTICLE', data })
})

const mapStateToProps = (state) => ({
	articleToEdit: state.home.articleToEdit,
	forEdit: state.home.forEdit
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
