import React, { Component } from 'react'
import {
	//Link as ScrollLink,
	animateScroll as scroll
} from 'react-scroll'
import { Link } from 'react-router-dom'
// import { TOKEN } from '../creds'
import style from './navbar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
//import { parseZone } from 'moment'

export default class Navbar1 extends Component {
	constructor(props) {
		super(props)
		this.state = {
			toggle: false
		}
	}

	scrollToTop = () => {
		scroll.scrollToTop()
	}
	burgerToggle = () => {
		this.setState({ toggle: !this.state.toggle })
		console.log(this.state.toggle)
	}
	render() {
		return (
			<div className={style.sticky}>

				<nav>
					<div className={style.navWide}>
						<div className={style.div}>
							<nav className={style.nav} id="navbar">
								<div className={style.navContent}>
									<ul className={style.navItems}>
										{/* <li className={style.navItem}>
											<Link style={{ whiteSpace: "nowrap" }} className={style.textColor} to="covid-19">
												COVID-19
											</Link>
										</li> */}
										<li className={style.navItem}>
											<Link className={style.textColor} to="home">
												Home
											</Link>
										</li>
										<li className={style.navItem}>
											<Link className={style.textColor} to="about">
												About
											</Link>
										</li>
										<li className={style.navItem}>
											<Link className={style.textColor} to="services">
												Services
											</Link>
										</li>

										<li className={style.navItem}>
											<Link style={{ whiteSpace: "nowrap" }} className={style.textColor} to="expect">
												Your Visit
											</Link>
										</li>
										<li className={style.navItem}>
											<Link className={style.textColor} to="questions">
												Questions
											</Link>
										</li>
										<li className={style.navItem}>
											<Link className={style.textColor} to="resources">
												Resources
											</Link>
										</li>
										<li className={style.navItem}>
											<Link className={style.textColor} to="contact">
												Contact
											</Link>
										</li>
										<li className={style.navItem}>
											<Link style={{ whiteSpace: "nowrap" }} className={style.textColor} to={{ pathname: "https://naturalstatepsychotherapy.clientsecure.me" }} target="_blank">
												Client Portal
											</Link>
										</li>
										{/* <li className={style.navItem}>
											<Link className={style.textColor} to="blog">
												Blog
									</Link>
										</li> */}
										{/* {localStorage.getItem('token') === TOKEN && (
											<li className={style.navItem}>
												<Link className={style.textColor} to="admin">
													Admin
										</Link>
											</li>
										)} */}
									</ul>
								</div>
							</nav>
						</div>
					</div>
					<div className={style.navNarrow}>
						<i onClick={this.burgerToggle}><FontAwesomeIcon style={{ fontSize: '25px' }} icon={faBars} /></i>
						<Link to='home' //onClick={this.setState({ burgerToggle: false })} 
							className={style.navtitle}>Meredith Kimbrough MS, LPC</Link>
						{this.state.toggle && (<div className={style.narrowLinks}>
							<ul className={style.navItemsDrop}>
								{/* <li className={style.navItemDrop}>
									<div style={{ paddingTop: 5 }} />
									<Link onClick={this.burgerToggle} className={style.textColor} to="covid-19">
										COVID-19
									</Link>
								</li> */}
								<li className={style.navItemDrop}>
									<Link onClick={this.burgerToggle} className={style.textColor} to="home">
										Home
									</Link>
								</li>
								<li className={style.navItemDrop}>
									<Link onClick={this.burgerToggle} className={style.textColor} to="about">
										About
									</Link>
								</li>
								<li className={style.navItemDrop}>
									<Link onClick={this.burgerToggle} className={style.textColor} to="services">
										Services
									</Link>
								</li>

								<li className={style.navItemDrop}>
									<Link onClick={this.burgerToggle} style={{ whiteSpace: "nowrap" }} className={style.textColor} to="expect">
										Your Visit
									</Link>
								</li>
								<li className={style.navItemDrop}>
									<Link onClick={this.burgerToggle} className={style.textColor} to="questions">
										Questions
									</Link>
								</li>
								<li className={style.navItemDrop}>
									<Link onClick={this.burgerToggle} className={style.textColor} to="resources">
										Resources
									</Link>
								</li>
								<li className={style.navItemDrop}>
									<Link onClick={this.burgerToggle} className={style.textColor} to="contact">
										Contact
									</Link>
								</li>
								<li className={style.navItemDrop}>
									<Link onClick={this.burgerToggle} style={{ whiteSpace: "nowrap" }} className={style.textColor} to={{ pathname: "https://naturalstatepsychotherapy.clientsecure.me" }} target="_blank">
										Client Portal
									</Link>
								</li>
								{/* <li className={style.navItemDrop}>
									<Link onClick={this.burgerToggle} className={style.textColor} to="blog">
										Blog
									</Link>
								</li> */}
								{/* {localStorage.getItem('token') === TOKEN && (
									<li className={style.navItemDrop}>
										<Link onClick={this.burgerToggle} className={style.textColor} to="admin">
											Admin
										</Link>
									</li>
								)} */}
							</ul>
						</div>)}
					</div>
				</nav>

				{/* <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
					<Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto">
							<Nav.Link href="#features">Features</Nav.Link>
							<Nav.Link href="#pricing">Pricing</Nav.Link>
							<NavDropdown title="Dropdown" id="collasible-nav-dropdown">
								<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
								<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
								<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
							</NavDropdown>
						</Nav>
						<Nav>
							<Nav.Link href="#deets">More deets</Nav.Link>
							<Nav.Link eventKey={2} href="#memes">
								Dank memes
      </Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Navbar> */}

				{/* <div className={style.div}>
					<nav className={style.nav} id="navbar">
						<div className={style.navContent}>
							<ul className={style.navItems}>
								<li className={style.navItem}>
									<Link className={style.textColor} to="home">
										Home
									</Link>
								</li>
								<li className={style.navItem}>
									<Link className={style.textColor} to="about">
										About
									</Link>
								</li>
								<li className={style.navItem}>
									<Link className={style.textColor} to="services">
										Services
									</Link>
								</li>

								<li className={style.navItem}>
									<Link style={{ whiteSpace: "nowrap" }} className={style.textColor} to="expect">
										Your Visit
									</Link>
								</li>
								<li className={style.navItem}>
									<Link className={style.textColor} to="questions">
										Questions
									</Link>
								</li>
								<li className={style.navItem}>
									<Link className={style.textColor} to="community">
										Community
									</Link>
								</li>
								<li className={style.navItem}>
									<Link className={style.textColor} to="contact">
										Contact
									</Link>
								</li>
								<li className={style.navItem}>
									<Link className={style.textColor} to="blog">
										Blog
									</Link>
								</li>
								{localStorage.getItem('token') === TOKEN && (
									<li className={style.navItem}>
										<Link className={style.textColor} to="admin">
											Admin
										</Link>
									</li>
								)}
							</ul>
						</div>
					</nav>
				</div> */}
			</div>
		)
	}
}
