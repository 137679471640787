import React from 'react'
import style from './services.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import Footer from '../Footer'
import { animateScroll as scroll } from 'react-scroll'
import { Link } from 'react-router-dom'
import logo from "./../../assets/naturalstatelogobg.png"
//import toys from "./../../assets/toys.jpg"
//https://fontawesome.com/icons?d=gallery&s=solid&m=free
class Services extends React.Component {
	scrollToTop = () => {
		scroll.scrollToTop()
	}
	componentDidMount() {
		//	this.scrollToTop();
	}
	render() {
		return (
			<div className={style.page}>
				<div className={style.aboutcontainer}>
					<div className={style.info}>
						<div className={style.listcontainer}>
							<div className={style.text}>
								<div className={style.title}>
									Services
									<div className={style.line} />

								</div>
								<div className={style.titlesm}>
									Individual Psychotherapy
								</div>
								{/* <div className={style.paragraph}>We will meet for 50-60 minutes to explore your concerns from a psychodynamic, attachment-based perspective.</div> */}

								<div className={style.titlesm}>
									Couples Therapy
								</div>
								{/* <div className={style.paragraph}>Informed by Emotionally Focused Therapy (EFT).
					<a target="_blank" rel="noopener noreferrer" className={style.href} href='https://iceeft.com/what-is-eft/'> Learn more here.</a>

								</div> */}

								{/* <div className={style.titlesm}>
									Play Therapy
									</div>
								<div className={style.paragraph}>For children 6+. We will explore your child’s concerns using child-centered play therapy, </div>

								<a target="_blank" rel="noopener noreferrer" className={style.href} href='https://cpt.unt.edu/what-is-play-therapy/'>learn more here.</a> */}
								{/* <div className={style.titlesm}>
									EMDR Therapy
								</div> */}
								<div className={style.titlesm}>
									Group Therapy
								</div>

								<div className={style.paragraph}>Group therapy will be offered periodically - if you are interested in attending a therapy group, let me know what type of group would be most useful for you.


								</div>


								{/* I work with children (ages 6+), adolescents, adults, and couples from an attachment-based, psychodynamic perspective. I will work with you on current and historical relationship patterns and provide support as you process and express emotion. Children’s concerns are addressed using child-centered play therapy methods. Group therapy will be offered periodically - if you are interested in attending a therapy group, let me know what type of group would be most useful for you.<br /> */}
								<div className={style.buttonContainer}>
									{/* <Link style={{ textDecoration: 'none', width: '200px' }} to="contact"><div className={style.button}>BOOK NOW</div></Link> */}


								</div>

								<div className={style.today}>
									Check out my profile on <br />
									<a className={style.button} style={{ width: '250px', textAlign: 'center' }} href='https://www.psychologytoday.com/us/therapists/meredith-kimbrough-fayetteville-ar/733444' target="_blank">PSYCHOLOGY TODAY</a>
									<br />

									{/* <img src={toys} alt='plant' className={style.toys} /> */}
								</div>
							</div>
							<div>
								<div>
									<img src={logo} alt='logo' className={style.logo} />
								</div>
								<div className={style.treatment}>Treatment For
									<div className={style.line} />
								</div>

								<ul className={style.ul}>

									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Life Transitions</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Relationship Issues</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Anger Management</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Anxiety</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Asperger's Syndrome</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Child and Adolescent Concerns</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Depression</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Divorce</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Emotional Disturbance</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Family Conflict</div></div></li>
									{/* <li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Narcissistic Personality</div></div></li> */}
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Intimacy</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Trauma</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Spirituality</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Stress</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Women's Issues</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Self Esteem</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Identity</div></div></li>
									<li><div className={style.list}><FontAwesomeIcon icon={faCircle} /><div className={style.listitem}>Adjustment</div></div></li>
								</ul>
								<div className={style.buttonContainer}>
									<Link style={{ textDecoration: 'none' }} to="contact"><div className={style.button}>BOOK NOW</div></Link>
									{/* <img src={toys} alt='plant' className={style.toys} /> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ paddingTop: '30px' }} />
				<Footer />

			</div>
		)
	}
}

export default Services
