import React from 'react'
import style from './home.module.scss'
import Footer from '../Footer'
// import PortalLink from '../PortalLink'
import { Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

class Home extends React.Component {
	constructor(props) {
		super(props)

		this.state = { scrolling: false }
	}
	scrollToTop = () => {
		scroll.scrollToTop()
	}
	componentDidMount() {
		this.scrollToTop();
		window.addEventListener('scroll', this.onScroll);
	}
	componentDidUpdate() {
		console.log(this.state.scrolling);
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll);
	}
	onScroll = () => {
		var doc = document.documentElement;
		var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
		if (top > 55) {
			this.setState({
				scrolling: true
			});
		}
		if (top < 55) {
			this.setState({
				scrolling: false
			});
		}
		console.log(top)

	};
	render() {
		return (
			<div className={style.wrapper1}>
				<div className={style.page} />
				<div className={style.homeContainer}>
					{/* <img src="https://dtjew9b6f6zyn.cloudfront.net/wp-content/uploads/2018/04/29791560_1684969654883294_4550413134514356224_n.jpg" className="homeImg1" alt="trees" /> */}
					<div className={style.text}>

						<div className={style.name}>
							Meredith Kimbrough <div className={style.title}> MS, LPC</div>
						</div>
						<div
							style={{ whiteSpace: 'nowrap' }} className={style.company}
						>
							Natural State Psychotherapy</div>
					</div>
					<div>
						<div className={style.greyStripe}>

						</div>
					</div>
					<div className={style.line}><Link style={{ textDecoration: 'none' }} to="about"><div className={style.button}>LEARN MORE</div></Link></div>



				</div>
				{/* <div className={style.aboutcontainer}> */}
				{/* <div className={style.text1}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
						et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
						cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
						culpa qui officia deserunt mollit anim id est laborum.
				</div> */}
				{/* <img src={portrait} className={style.portrait} alt='portrait'></img> */}


				{/* </div> */}
				{/* <div className={style.joypage}></div> */}
				{/* <div style={{ opacity: this.state.scrolling ? 0 : 1 }}>
					<PortalLink />
				</div> */}

				<Footer />

			</div>
		)
	}
}
//
export default Home
