const home = (state = { articles: [] }, action) => {
    switch (action.type) {
        case 'HOME_PAGE_LOADED':
            console.log('HOME_PAGE_LOADED', action.data.articles)
            return {
                ...state,
                articles: action.data.articles,
            };
        case 'SUBMIT_ARTICLE':
            console.log('SUBMIT_ARTICLE', action.data.articles)
            return {
                ...state,
                articles: action.data.articles,
            };
        case 'DELETE_ARTICLE':
            console.log('DELETE_ARTICLE', action.data.articles)
            return {
                ...state,
                articles: action.data.articles,
            };
        case 'SET_EDIT':
            console.log('SET_EDIT', action.article)
            return {
                ...state,
                articleToEdit: action.article,
            };
        case 'EDIT_ARTICLE':
            console.log('EDIT_ARTICLE', action.data.articles)
            return {
                ...state,
                articles: action.data.articles,
            };
        case 'FOR_EDIT':
            console.log('FOR_EDIT', action.forEdit)
            return {
                ...state,
                forEdit: action.forEdit,
            };
        default:

            return state;
    }
}

export default home
