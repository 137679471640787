import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import style from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

const Marker = ({ text }) => <div style={{ color: 'green', fontSize: '20px', width: '140px' }}><FontAwesomeIcon icon={faMapMarkerAlt} style={{ fontSize: '20px' }} />{text}</div>;

class SimpleMap extends Component {
    static defaultProps = {
        center: {
            lat: 36.062612,
            lng: -94.158624
        },
        zoom: 17
    };

    render() {
        return (
            // Important! Always set the container height explicitly

            <div style={{
                height: '300px', maxWidth: '600px', width: '80vw',
            }}>
                <GoogleMapReact

                    options={{
                        styles: style,
                        zoomControl: false,
                        draggable: false

                    }}
                    bootstrapURLKeys={{ key: 'AIzaSyAulC_L2cwe0d9w7eSGaSyMgIGVpgtyrIY' }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/31+E+Center+St,+Fayetteville,+AR+72701/@36.0624716,-94.1606039,17z/data=!3m1!4b1!4m5!3m4!1s0x87c96ed6488281bd:0xcaefa1377d9c2876!8m2!3d36.0624716!4d-94.1584152">

                        <Marker
                            // lat={36.062612}
                            // lng={-94.158624}
                            text="  Natural State Psychotherapy"
                        />
                    </a>
                </GoogleMapReact>
            </div>

        );
    }
}

export default SimpleMap;