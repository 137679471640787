// https://tylermcginnis.com/react-router-protected-routes-authentication/

import React from 'react'
import { withRouter } from 'react-router-dom'
import { USER, PASS, TOKEN } from '../creds'
import style from './login.module.scss'

class Login extends React.Component {
	handleSignIn(e) {
		e.preventDefault()
		let username = this.refs.username.value
		let password = this.refs.password.value
		//console.log(username, password)
		if (username === USER && password === PASS) {
			localStorage.setItem('token', TOKEN)
			this.props.history.push('/admin')
		} else {
			alert('Please check your login credentials')
		}
	}
	render() {
		return (
			<div className={style.body}>
				<form onSubmit={this.handleSignIn.bind(this)}>
					<div className={style.field}><input type="text" ref="username" placeholder="Username" /></div>
					<div className={style.field}><input type="password" ref="password" placeholder="Password" /></div>
					<div className={style.field}><button type="submit" value="Login">Login</button></div>
				</form>
			</div>
		)
	}
}
export default withRouter(Login)
